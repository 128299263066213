@import "../../styles/mixins";

.hero__section {
  padding-top: 200px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 2em;
    text-align: left;
    @include tablet {
      text-align: unset;
    }
    @include desktop {
      width: 90%;
      margin: 0 auto;
    }
  }

  & .hero__image {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    @include desktop {
      width: 50%;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }
}

.page__content {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;

  & svg {
    width: 99px;
    height: 99px;
  }
  & h2 {
    font-size: 1.5em;
    font-weight: 400;
    color: $primaryDarkColor;
    margin-top: 40px;
    @include desktop {
      width: 80%;
      margin: 0 auto;
    }
  }

  & .page__stores {
    display: none;
    @include desktop {
      display: block;
    }
  }
}

.mockup__section {
  background: url("../../assets/images/backgroundImage.png") no-repeat top
    center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 200px;
  text-align: center;

  & .mockup__image {
    width: 100%;
    text-align: center;
    @include desktop {
      width: 50%;
      margin: 0 auto;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }
}
