@import "../../styles/mixins";

.button {
  display: block;
  width: 100%;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px 55px;
  border-radius: 5px;
  background: $primaryColor;
  color: #fff;
  text-align: center;

  &:hover {
    background: $textColor;
    color: #fff;
  }

  @include transition(background 150ms ease-in-out);
  @include desktop {
    display: unset;
    width: unset;
  }
}
