@import "../../styles/mixins";

.hero__section {
  padding-top: 150px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 1.5em;
  }

  & .hero__content {
    width: 100%;
    @include desktop {
      width: 60%;
    }
  }

  & .hero__image {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    @include desktop {
      width: 50%;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }
}

.page__content {
  background: url("../../assets/images/backgroundImage.png") no-repeat top
    center;
  background-size: cover;
}

.page__content {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;

  & h2 {
    font-size: 1.87em;
    text-align: center;
    @include desktop {
      text-align: unset;
      width: 40%;
    }
  }

  & .page__image {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    @include desktop {
      width: 50%;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }
}

.benefits {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 30%
  );

  padding-top: 100px;
  padding-bottom: 100px;

  & h2 {
    font-size: 2em;
    text-align: center;
    margin-top: 0;
  }

  & .benefits__rows {
    margin-top: 40px;
  }
}
