@import "../../styles/mixins";

.box {
  padding: 40px;
  border-radius: 10px;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 30px;
  background: #fff;

  @include boxshadow(0 4px 20px 10px $lightBoxShadow);
  @include transition(background 150ms ease-in-out);

  @include desktop {
    display: flex;
    justify-content: space-between;
  }

  & span {
    color: #bbb;
    @include desktop {
      font-size: 1.5em;
    }
  }

  & h2 {
    margin-top: 5px;
    color: $primaryColor;
    margin-bottom: 20px;
    font-size: 1.125em;
    @include desktop {
      font-size: 2em;
    }
  }

  & .numPoint {
    @include desktop {
      width: 15%;
    }
  }

  & .content {
    @include desktop {
      width: 85%;
    }
  }

  &:hover {
    background: $secondaryColor;
    color: #fff;

    & h2 {
      color: #fff;
    }

    & p {
      color: $textLight2Color;
    }
  }
}
