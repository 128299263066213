@import "../../../styles/mixins";

.contact__form {
  color: #fff;
  width: 100%;

  & .input__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }

    & .input__control {
      @include tablet {
        width: calc(50% - 30px);
      }
    }
  }

  & .input__control {
    margin-bottom: 22px;

    & label {
      display: block;
      margin-top: 0;
      font-size: 1.125em;
      font-weight: 500;
    }

    & input,
    & textarea,
    & select {
      color: #fff;
      border: 1px solid #888888;
      background: rgba(52, 52, 52, 0.8);
      width: 100%;
      height: 50px;
      border-radius: 10px;
      font-size: 0.875em;
      padding: 20px;
    }

    & select {
      -webkit-appearance: none;
      padding: 0 20px;
      color: #fff;
    }
    & textarea {
      height: 250px;
      max-height: 350px !important;
      min-height: 250px !important;
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  @include desktop {
    width: unset;
  }
}

.mailForm {
  & label {
    padding-right: 20px;
  }
  & div {
    padding-bottom: 10px;
  }
}

.btnSend {
  width: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 50px;
}
.formConfirmation {
  color: green;
}

p.errorMsg {
  height: 20px;
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-bottom: 0;
}

.formConfirmation {
  color: #fff;
  text-align: center;
  background: $primaryColor;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
