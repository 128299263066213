@import "../../styles/mixins";

.hero__section {
  padding-top: 200px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1,
  & h2 {
    font-size: 1.5em;
    @include desktop {
      font-size: 2.5em;
    }
  }
}

.arrow {
  margin-bottom: 50px;
}
.arrow svg {
  width: 90px;
  height: 90px;
}

.page__content__benefits {
  padding-top: 150px;
  padding-bottom: 150px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  text-align: center;

  & svg {
    width: 101px;
    height: 101px;
  }

  & h2 {
    margin-top: 70px;
    font-size: 1.875em;
    margin-bottom: 70px;
  }

  & ul {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px;

    @include desktop {
      width: 700px;
    }

    & li {
      font-size: 1.375em;
      margin-bottom: 20px;
    }
  }

  & .image_box {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    @include desktop {
      width: 90%;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }
}

.page__content {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 80px;

  & svg {
    width: 99px;
    height: 99px;
  }
  & h2 {
    font-size: 1.5em;
    font-weight: 400;
    color: $primaryDarkColor;
    margin-top: 40px;
    @include desktop {
      width: 80%;
      margin: 0 auto;
    }
  }

  & .page__stores {
    display: none;
    @include desktop {
      display: block;
    }
  }
}

.page__content__user {
  background: url("../../assets/images/backgroundImage.png") no-repeat top
    center;
  background-size: cover;

  color: #fff;

  & ul {
    margin-bottom: 0;

    & li {
      margin-bottom: 20px;
      font-size: 1.25em;

      @include tablet {
        margin-bottom: 0;
      }
    }
  }
}
