@import "../../styles/mixins";

.hero__section {
  padding-top: 150px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 2.5em;
  }

  & h2 {
    font-size: 1.25em;
    width: 80%;
    font-weight: 400;
    color: #878787;
  }

  & .hero__content {
    width: 100%;
    @include desktop {
      width: 60%;
    }
  }
}
