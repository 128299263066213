// Screen sizes
$screen-sm: 578px;
$screen-md: 960px;
$screen-lg: 1080px;

// Colors
$textColor: #000;
$textColorLight: #bbb;
$textLight2Color: #eaeaea;

$primaryDarkColor: #414141;
$primaryColor: #2cc092;

$lightBoxShadow: rgba(229, 229, 229, 0.25);

$secondaryColor: #219470;
