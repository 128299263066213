@import "../../styles/mixins";

.box {
  padding: 24px 19px 20px 19px;
  border-radius: 8px;
  width: 244px;
  margin-left: 13px;
  margin-right: 13px;
  background: #fff;

  @include boxshadow(0 4px 20px 10px $lightBoxShadow);
  @include transition(background 150ms ease-in-out);

  & svg {
    position: relative;
    z-index: 2;
    width: 38px;
    height: 38px;
  }

  & span {
    display: block;
    margin-top: 40px;
    color: $textColorLight;
    margin-bottom: 5px;
  }

  & h2 {
    margin-top: 5px;
    color: $primaryColor;
    margin-bottom: 5px;
    font-size: 1.125em;
  }

  & .rect {
    z-index: 1;
    width: 60px;
    height: 10px;
    border-radius: 2px;
    background: $primaryColor;
    opacity: 0.4;
    position: relative;
    top: -14px;
    left: 10px;
  }

  &:hover {
    background: $secondaryColor;
    color: #fff;

    & svg path,
    & h2 {
      fill: #fff;
      color: #fff;
    }

    & span {
      color: $textLight2Color;
    }
  }
}
