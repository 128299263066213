@import "../../styles/mixins";

.contact__section {
  background: url("../../assets/images/backgroundImage.png") no-repeat top
    center;
  background-size: cover;
}

.contact__container {
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;

  @include desktop {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.contact__content {
  color: #fff;
  flex-basis: 100%;
  margin-bottom: 50px;
  text-align: center;

  & h2 {
    font-size: 1.875em;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  & p {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 1.2em;
    font-weight: 500;
    @include desktop {
      font-size: 1.25em;
    }
  }

  & svg {
    width: 37px;
    height: 37px;
  }

  @include desktop {
    flex-basis: 45%;
  }
}

.contact__form {
  color: #fff;
  width: 100%;

  & .input__row {
    @include tablet {
      display: flex;
      justify-content: space-between;
    }

    & .input__control {
      @include tablet {
        width: calc(50% - 30px);
      }
    }
  }

  & .input__control {
    margin-bottom: 22px;

    & label {
      display: block;
      margin-top: 0;
      font-size: 1.125em;
      font-weight: 500;
    }

    & input,
    & textarea,
    & select {
      color: #fff;
      border: 1px solid #888888;
      background: rgba(52, 52, 52, 0.8);
      width: 100%;
      height: 50px;
      border-radius: 10px;
      font-size: 0.875em;
      padding: 20px;
    }

    & select {
      -webkit-appearance: none;
      padding: 0 20px;
      color: #fff;
    }
    & textarea {
      height: 250px;
      max-height: 350px !important;
      min-height: 250px !important;
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  @include desktop {
    width: unset;
  }
}

.btnSend {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.btnSendDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: unset;
  background: $primaryColor;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btnSendDisabled svg {
  width: 40px;
  height: 40px;
}

.btnSendDisabled:hover {
  background: $primaryColor;
}

p.errorMsg {
  height: 20px;
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-bottom: 0;
}

.formConfirmation {
  color: #fff;
  text-align: center;
  background: $primaryColor;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
