@import "./mixins";
@import "./variables";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: $textColor;
}

img {
  width: 100%;
}

.container {
  width: 90%;
  margin: 0 auto;
  @include desktop {
    width: $screen-lg;
  }
}

a {
  text-decoration: none;
  &.active {
    color: $primaryColor !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--center {
    justify-content: center;
  }

  &--space {
    justify-content: space-between;
  }

  &--column {
    flex-direction: column;
  }

  @include desktop {
    flex-wrap: nowrap;
  }
}

.text-center {
  text-align: center;
}

.green {
  color: $primaryColor;
}

.break {
  display: block;
}

.padding {
  padding: 80px 0 80px 0;
}

.hide-lg {
  @include desktop {
    display: none !important;
  }
}

.hide-sm {
  display: none !important;
  @include desktop {
    display: block !important;
  }
}

/* ---- TO REMOVE -------- */
.starter-placeholder {
  padding-top: 100px;
  text-align: center;
}

.asd-image {
  width: 250px;
}

.ostale-slike {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
