@import "../../styles/mixins";

.box {
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;

  & .numPoint {
    text-align: left;
    color: $primaryColor;
    font-weight: bold;
    font-size: 10em;
    position: absolute;
    opacity: 0.2;

    @include desktop {
      position: static;
      opacity: 0.3;
      width: 30%;
    }
  }

  & .box__content {
    z-index: 2;

    & h3 {
      margin-top: 0;
      text-transform: uppercase;
      color: $primaryDarkColor;
      font-size: 1.625em;
      margin-bottom: 12px;
    }

    & p {
      margin-top: 12px;
      font-size: 1.2em;
      color: #525252;
      margin-bottom: 0;

      @include desktop {
        font-size: 1.375em;
      }
    }

    @include desktop {
      width: 70%;
    }
  }
}
.reverse {
  & .numPoint {
    text-align: right;
    order: 2;
  }
}
