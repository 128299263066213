@import "../../styles/mixins";

.header {
  padding-top: 30px;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;

  @include transition(all 150ms ease-in-out);

  &.scroll {
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    @include boxshadow(0 4px 4px 0 rgb(211, 211, 211));
  }

  &.active {
    background: #3d3d3d;
    & .hamburger__menu div {
      width: 32px;
      margin: 0;
      position: absolute;
      top: 16px;
      background: #fff;

      &:nth-child(2) {
        display: none;
      }

      &:first-child {
        position: relative;
        top: -4px;
        @include rotate(45deg);
      }

      &:last-child {
        @include rotate(-45deg);
      }
    }

    & .brand span {
      color: #f4f4f4;
    }

    & .mobile__navigation {
      display: block;
    }
  }
}

.mobile__navigation {
  display: none;
  text-align: center;

  @include transition(all 150ms ease-in-out);
  & ul {
    padding: 50px 0;
  }

  & li {
    display: block;
    margin: 10px;
  }

  & a {
    padding: 10px;
    display: block;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }

  & .cta a {
    width: 95%;
    margin: 0 auto;
    padding: 10px 38px;
    border-radius: 5px;
    background: #2cc092;
    color: #fff;
  }
}

.brand {
  display: flex;
  align-items: center;

  & span {
    display: none;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline;

    @include mobile {
      display: inline-block;
    }

    @include desktop {
      display: none;
    }
  }

  & img {
    display: block;
    width: 38px;

    @include tablet {
      width: unset;
    }
  }
}

.hamburger__menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background: none;
  border: none;
  position: relative;
  width: 44px;
  height: 44px;

  & div {
    width: 32px;
    height: 4px;
    background: #2cc092;
    margin-bottom: 7px;
    border-radius: 4px;
    @include transition(all 150ms ease-in-out);

    &:first-child {
      width: 24px;
    }

    &:last-child {
      margin-bottom: 0;
      width: 18px;
    }
  }

  @include desktop {
    display: none;
  }
}

.navigation {
  display: none;

  & ul {
    padding: 0;
    margin: 0;
  }

  & li {
    display: inline-block;
    margin-right: 25px;
    margin-left: 25px;

    &.cta {
      a {
        padding: 10px 38px;
        border-radius: 5px;
        background: #2cc092;
        color: #fff !important;

        @include transition(background 150ms ease-in-out);

        &:hover {
          background: #000000;
        }
      }
    }
  }

  & a {
    font-weight: 500;
    color: #414141;
    text-transform: uppercase;
    @include transition(color 150ms ease-in-out);

    &:hover {
      color: $primaryColor;
    }
  }

  @include desktop {
    display: block;
  }
}

.cookieBanner {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  background: rgba(44, 192, 146, 0.95);
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}

.cookieBanner button {
  background: unset;
  border: 2px solid #fff;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  @include transition(all 150ms ease-in-out);
}

.cookieBanner a {
  color: #000;
  text-decoration: underline;
}

.cookieBanner button:hover {
  background: #fff;
  border: 2px solid #fff;
  color: $primaryColor;
}
