@import "../../styles/mixins";

.hero__section {
  padding-top: 200px;
  padding-bottom: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 1.5em;
    @include desktop {
      font-size: 2.5em;
    }
  }
}

.page__content {
  padding-top: 20px;
  padding-bottom: 150px;
  background: rgb(255, 255, 255);

  & h2 {
    margin-top: 70px;
    font-size: 1.875em;
    margin-bottom: 70px;
  }
}
