@import "../../styles/mixins";

.hero__section {
  padding-top: 200px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 2em;
  }

  & svg {
    width: 300px;
    height: 300px;

    @include tablet {
      width: 500px;
      height: 500px;
    }
  }
}
