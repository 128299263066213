.mobile__stores {
  display: block;
  padding: 0;

  & li {
    display: inline-block;
    margin-top: 10px;

    &:last-child {
      margin-left: 20px;
    }
  }

  & a svg {
    width: 177px;
    height: 53px;
  }
}
