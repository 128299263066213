@import "../../styles/mixins";

.hero__section {
  padding-top: 150px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 2.5em;
  }

  & h2 {
    font-size: 1.25em;
    width: 80%;
    font-weight: 400;
    color: #878787;
  }

  & .hero__content {
    width: 100%;
    @include desktop {
      width: 60%;
    }
  }

  & .hero__image {
    width: 100%;
    margin: 30px auto;
    text-align: center;
    @include desktop {
      width: 50%;
    }

    & img {
      width: 100%;
      @include mobile {
        width: 434px;
      }
    }
  }

  & .hero__stores {
    margin: 0 auto;
    text-align: center;

    & li {
      display: block;
      @include mobile {
        display: inline-block;
      }
    }

    & li:last-child {
      margin-left: 0;
      @include mobile {
        margin-left: 20px;
      }
    }
  }
}

.benefits__grid div {
  width: 100%;
  margin-bottom: 30px;
  @include tablet {
    width: 45%;
  }

  @include desktop {
    width: 244px;
  }
}

.partner__image {
  margin: 0 auto;
  order: 2;

  & img {
    display: block;
    position: relative;
    z-index: 2;
  }

  & .partner__underbox {
    top: -100px;
    position: relative;
    width: 100%;
    height: 165px;
    background: #fff;
    -webkit-box-shadow: 0 4px 20px 10px rgba(229, 229, 229, 0.25);
    box-shadow: 0 4px 20px 10px rgba(229, 229, 229, 0.25);
    border-radius: 8px;

    & div {
      position: absolute;
      bottom: 45px;
      left: 50px;
      width: 125px;
      height: 21px;
      border-radius: 2px;
      background: #2cc092;
      opacity: 0.4;
    }
  }

  @include desktop {
    order: unset;
    margin-right: 80px;
  }
}

.partner__content {
  width: 100%;
  & h2 {
    font-size: 1.875em;
    margin-top: 0;
  }

  & p {
    color: #878787;
    font-size: 1.125em;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  & ul li {
    font-weight: 500;
    color: #414141;
    font-size: 1.125em;
  }

  & ul {
    margin-bottom: 50px;
    padding-left: 25px;
  }

  @include desktop {
    width: unset;
  }
}

.contact__section {
  background: url("../../assets/images/backgroundImage.png") no-repeat top
    center;
  background-size: cover;
}

.contact__container {
  align-items: flex-start;
  padding-top: 220px;
  padding-bottom: 220px;

  @include desktop {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.contact__content {
  color: #fff;
  flex-basis: 100%;
  margin-bottom: 50px;

  & h2 {
    font-size: 1.875em;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  & p {
    margin-top: 40px;
    font-size: 1.25em;
    font-weight: 500;

    & a {
      color: $primaryColor;
      font-weight: 600;
    }
  }

  & svg {
    width: 37px;
    height: 37px;
  }

  @include desktop {
    flex-basis: 45%;
  }
}

.contact__form {
  color: #fff;
  width: 100%;

  & label {
    display: block;
    margin-top: 0;
    font-size: 1.125em;
    font-weight: 500;
  }

  & .input__control {
    margin-bottom: 42px;

    & input,
    & textarea {
      color: #fff;
      border: 1px solid #888888;
      background: rgba(52, 52, 52, 0.8);
      width: 100%;
      height: 50px;
      border-radius: 10px;
      font-size: 0.875em;
      padding: 20px;
    }

    & textarea {
      height: 250px;
      max-height: 350px !important;
      min-height: 250px !important;
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  @include desktop {
    width: unset;
  }
}
