@import "../../styles/mixins";

.hero__section {
  padding-top: 200px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(217, 231, 226, 1) 0%,
    rgba(196, 196, 196, 0) 100%
  );

  & h1 {
    font-size: 2em;

    & a {
      border-bottom: 5px solid $primaryColor;
      color: $primaryColor;
      @include transition(background 150ms ease-in-out);
    }
  }
}

.arrow svg {
  width: 90px;
  height: 90px;
}

.questions {
  margin-top: 100px;
  margin-bottom: 100px;
}
