@import "../../styles/mixins";

.input__group {
  margin-top: 20px;
  margin-bottom: 20px;

  & label {
    font-size: 0.875em;
    font-weight: 600;
    display: block;
  }

  & input {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid $primaryDarkColor;
    font-size: 0.875em;
  }
}
