@import "../../styles/mixins";

.footer {
  background: $textColor;
  color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}

.QR {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;

  & svg path {
    fill: #fff;
  }

  & svg rect {
    fill: #000;
  }

  & p {
    width: 220px;
  }

  @include desktop {
    display: flex;
  }
}

.footer__links {
  padding: 0;
  display: none;
  & li {
    display: inline-block;
    text-transform: uppercase;
    margin: 30px 10px 30px 10px;
    margin-bottom: 12px;
  }

  & a {
    color: #fff;
    padding: 20px;

    @include transition(color 150ms ease-in-out);

    &:hover {
      color: $primaryColor;
    }
  }

  @include tablet {
    display: block;
  }
}

.footer__social {
  margin-top: 50px;
  display: flex;
  padding: 0;

  & li {
    display: inline-block;
    text-transform: uppercase;
    margin: 0 10px;

    & span {
      display: none;
    }

    & svg {
      width: 34px;
      height: 34px;

      & path {
        @include transition(all 150ms ease-in-out);
      }
    }
  }

  & a {
    padding: 10px;

    &:hover svg path {
      fill: $primaryColor;
    }
  }
}

.footer__stores {
  & li {
    display: block;

    &:last-child {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  @include desktop {
    display: none;
  }
}

.copyright__links {
  padding: 0;

  & li {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
  }

  & a {
    color: #fff;
    padding: 5px;
    @include transition(color 150ms ease-in-out);

    &:hover {
      color: $primaryColor;
    }
  }
}
