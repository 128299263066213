@import "../../../styles/mixins";

.adminSidebar {
  padding: 24px;
  width: 70px;
  height: 100%;
  position: fixed;
  background: #fff;
  border-right: 1px solid $textColor;

  @include desktop {
    width: 18%;
  }

  & > p {
    cursor: pointer;
    display: none;
    @include desktop {
      display: block;
    }
  }

  .logoutBtn {
    display: none;
    @include desktop {
      display: block;
    }
  }

  &.active {
    background: #3d3d3d;
    width: 50%;

    & .brand span {
      color: #f4f4f4;
    }

    & .mobile__navigation {
      display: block;
    }
  }
}

.sidebarLayout {
  width: 80%;
  height: 100%;
  margin-left: 70px;
  @include desktop {
    margin-left: 20%;
  }
}

.mobile__navigation {
  display: none;
  color: #f4f4f4;

  @include transition(all 150ms ease-in-out);
  & ul {
    padding: 20px 0;
  }

  & li {
    display: block;
    margin: 10px;
    cursor: pointer;
  }
}
