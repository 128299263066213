@import "./variables";

@mixin desktop {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin transition($option...) {
  -webkit-transition: $option;
  -moz-transition: $option;
  -ms-transition: $option;
  -o-transition: $option;
  transition: $option;
}

@mixin boxshadow($option) {
  -webkit-box-shadow: $option;
  box-shadow: $option;
}

@mixin rotate($value) {
  -moz-transform: rotate($value);
  -webkit-transform: rotate($value);
  -o-transform: rotate($value);
  -ms-transform: rotate($value);
  transform: rotate($value);
}
